import Payment from "payment";


export const renderRating = (rating) => {
  let ratings = [];

  for (let i = 0; i <= rating; i++) {
    ratings.push(<div className='star'><img alt='' src='/homeProducts/star.svg' /></div>);
  }

  return ratings;
}

export const formatPrice = (price) => {
  let USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  return `${USDollar.format(price)}`;
}

export const formatDate = (dateString) => {
  const options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', options);
};

const clearNumber = (value = "") => {
  return value.replace(/\D+/g, "");
}

export const formatCreditCardNumber = (value) => {
  if (!value) {
    return value;
  }

  const issuer = Payment.fns.cardType(value);
  const clearValue = clearNumber(value);
  let nextValue;

  switch (issuer) {
    case "amex":
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10
      )} ${clearValue.slice(10, 15)}`;
      break;
    case "dinersclub":
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10
      )} ${clearValue.slice(10, 14)}`;
      break;
    default:
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        8
      )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`;
      break;
  }

  return nextValue.trim();
}

export const formatCVC = (value, prevValue, allValues = {}) => {
  const clearValue = clearNumber(value);
  let maxLength = 4;

  if (allValues.number) {
    const issuer = Payment.fns.cardType(allValues.number);
    maxLength = issuer === "amex" ? 4 : 3;
  }

  return clearValue.slice(0, maxLength);
}

export const formatExpirationDate = (value) => {
  const clearValue = clearNumber(value);

  if (clearValue.length >= 3) {
    return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
  }

  return clearValue;
}

export const formatFormData = (data) => {
  return Object.keys(data).map(d => `${d}: ${data[d]}`);
}

export const groupArrayInChunks = (array, chunkSize) => {
  return array.reduce((result, item, index) => {
    const chunkIndex = Math.floor(index / chunkSize);
    if (!result[chunkIndex]) {
      result[chunkIndex] = [];
    }
    result[chunkIndex].push(item);
    return result;
  }, []);
};

export const containsLiveEdge = (inputString) => {
  // Define a regular expression to match "Live Edge" with any case sensitivity and ignore special characters.
  const regex = /live\s*edge/i;

  // Test if the input string matches the pattern
  return regex.test(inputString);
}

