import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { updateHeader } from "../../slices/header-slice";
import Animate from '../../components/animate';

import './styles.scss';
import Accordion from "../../components/accordion";

const FAQs = () => {
    const dispatch = useDispatch();
    const faqs = useSelector((state) => state.app.faqs);

    useEffect(() => {
        dispatch(updateHeader('light'))
    }, [dispatch]);

    return (
        <div className="faqs">
            <h1>FAQ</h1>

            <Animate>
                <div className="content">
                    {faqs && faqs.map((faq) => (
                        <>
                            <h2>{faq.section}</h2>
                            <Accordion data={faq.content} />
                        </>

                    ))}
                </div>
            </Animate>


        </div>
    )
}

export default FAQs