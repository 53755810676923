
import { useState } from "react";
import './styles.scss';
import { subscribeToNewsletter } from "../../api";

const Subscribe = ({ onClose }) => {
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsloading] = useState(false);

    const [state, setState] = useState({
        email: ""
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setState((prev) => ({ ...prev, [name]: value }));
    };

    const submitSubscribe = (e) => {
        e.preventDefault();
        setIsloading(true);
        subscribeToNewsletter(state).then((res) => {
            if (res.status === 200 || res.status === 204) {
                setIsSuccess(true);
                setIsError(false);
            } else {
                setIsError(true);
            }

            setIsloading(false);
        }).catch((error) => {
            setIsSuccess(false);
            setIsError(true);
            setIsloading(false);
        });
    };

    return (
        <div className='subscribe'>
            <h2>Subscribe to our Newsletter</h2>
            <p>Get the latest news from our studio.</p>
            <input type='email' name="email" defaultValue="" onChange={(e) => handleInputChange(e)} placeholder='Email' />

            {onClose
                ? <div className="subscribe-modal-actions">
                    <button className={`cta gold-fill ${isLoading ? "loading" : ""}`} onClick={(e) => submitSubscribe(e)}>
                        <div className="loader"></div>
                        <span>Subscribe</span>
                    </button>
                    <button className="cta dark" onClick={() => onClose()}>Close</button>
                </div>
                : <button className={`cta gold-fill ${isLoading ? "loading" : ""}`} onClick={(e) => submitSubscribe(e)}>
                    <div className="loader"></div>
                    <span>Subscribe</span>
                </button>}
            {isSuccess && <p className="success">Thank you! You are now subscribed.</p>}
            {isError && <p className="error">Unfortunately something went wrong. Please try again.</p>}
        </div>
    )
}

export default Subscribe;