import { useEffect, useState, useCallback } from 'react';
import './styles.scss';
import { useSelector, useDispatch } from 'react-redux';
import { Plus } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import { updateHeader } from '../../slices/header-slice';

import { LazyLoadedGroup } from '../../components/lazy-loaded-group';
import { groupArrayInChunks } from '../../utility/utils';

const Slabs = ({ type }) => {
    const navigate = useNavigate();
    const slabs = useSelector((state) => state.app.slabs);
    const dispatch = useDispatch();
    const [filters, setFilters] = useState([]);
    const [species, setSpecies] = useState([]);
    const [activeFilter, setActiveFilter] = useState(null);
    const [activeSpecies, setActiveSpecies] = useState("");
    const [filterResults, setFilterResults] = useState([]);
    const [filterOpen, setFilterOpen] = useState(false);

    useEffect(() => {
        dispatch(updateHeader('light'));

        if (slabs) {
            const categories = slabs.map(x => x.filter);
            const unique = categories.filter((c, index) => categories.indexOf(c) === index);

            const speciesOptions = slabs.map(x => x.species);
            const uniqueSpecies = speciesOptions.filter((s, index) => speciesOptions.indexOf(s) === index);

            setFilters(unique);
            setSpecies(uniqueSpecies);
            setFilterResults(groupArrayInChunks(slabs, 6));
        }
    }, [dispatch, slabs, type]);

    useEffect(() => {
        let filteredResults = slabs;

        if (activeFilter) {
            filteredResults = filteredResults.filter(x => x.filter === activeFilter);
        }

        if (activeSpecies) {
            filteredResults = filteredResults.filter(x => x.species === activeSpecies);
        }

        setFilterResults(groupArrayInChunks(filteredResults, 6));
    }, [activeFilter, activeSpecies, slabs]);

    const clearFilter = useCallback(() => {
        setActiveFilter(null);
        setActiveSpecies(""); // Reset species to "All species"
        setFilterResults(groupArrayInChunks(slabs, 6));
    }, [slabs]);

    const onProductClick = (item) => {
        navigate({
            pathname: `/slabs/${item.id}`,
            search: `?title=${item.title}`,
        });
    };

    return (
        <div className="slabs-page">
            <div className="store-intro">
                <h1>Live Edge Slabs.</h1>
                <p>We offer a range of Live Edge Slabs listed below that can be purchased separately for your project needs...</p>
            </div>

            <div className="tools-wrap">
                <div className="tools">
                    <div className="filter" onClick={() => setFilterOpen(!filterOpen)}>
                        {filterOpen ? <span>Close</span> : <span>Filters <Plus /></span>}
                    </div>
                    <div className="sort"></div>
                </div>
                {filterOpen && (
                    <div className={`filter-content ${filterOpen ? "open" : ""}`}>
                        <div className="filter-options">
                            {filters.map((filter, i) => (
                                <div key={i}>
                                    <label className="custom-radio">
                                        <input type="radio" name="filter" checked={filter === activeFilter} onChange={() => setActiveFilter(filter)} value={filter} />
                                        <span className="checkmark"></span>
                                        {filter}
                                    </label>
                                </div>
                            ))}

                            {species && (
                                <select className='custom-select' value={activeSpecies} onChange={(e) => setActiveSpecies(e.target.value)}>
                                    <option value="">All Species</option> {/* Default option */}
                                    {species.map((option, i) => (
                                        <option key={i} value={option}>{option}</option>
                                    ))}
                                </select>
                            )}
                        </div>
                        <div className="filter-buttons">
                            <button className="cta dark" onClick={clearFilter}>Clear</button>
                        </div>
                    </div>
                )}
            </div>

            <div className='lazy-load'>
                {filterResults &&
                    filterResults.map((group, i) => (
                        <LazyLoadedGroup
                            key={i}
                            group={group}
                            index={i}
                            type={type}
                            onProductClick={onProductClick}
                        />
                    ))}
            </div>
        </div>
    );
};

export default Slabs;
