import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updateHeader } from './slices/header-slice';
import { Routes, Route } from 'react-router-dom';
import { updateData } from './slices/app-slice'
import { HelmetProvider } from 'react-helmet-async';

import './App.scss'

//Components
import Header from './components/header'
import Footer from './components/footer'
import Splash from './components/splash';
import Showcase from './components/showcase';
import CartPreview from './components/cart-preview';
import Arrow from './components/arrow';

//Pages
import Product from './pages/product';
import OurStory from './pages/story/our-story';
import JeffreyGreene from './pages/jeffrey-greene';
import Custom from './pages/custom/custom';
import CheckOut from './pages/checkout';
import TableBuilder from './pages/table-builder';
import Cart from './pages/cart';
import Store from './pages/store';
import Home from './pages/home/home';
import Portfolio from './pages/portfolio/portfolio';
import FAQs from './pages/faqs';
import Contact from './pages/contact/contact';
import AdditionalServices from './pages/additional-services';
import Slabs from './pages/slabs';
import Bases from './pages/bases';
import WishList from './pages/wishlist';
import SubscribeModal from './components/subscribe-modal';
import Media from './pages/media';
import MediaPost from './pages/media-post';
import Announcement from './components/announcement';
import Divergence from './pages/divergence';

function App() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [showCaseItem, setShowcaseItem] = useState(null);
  const [data, setData] = useState(null);
  const [isFirstVisit, setIsFirstVisit] = useState(false);

  let url = "/data/data.json";
  url = `${process.env.REACT_APP_WEBSITE_API}/api/website`

  useEffect(() => {
    const p = fetch(url).then((data) => data.json());
    p.then(res => {
      dispatch(updateData(res));
      setData(res)
    })
  }, [dispatch, url]);


  useEffect(() => {
    const visited = localStorage.getItem('paramount_wood_co_visited');
    if (!visited) {
      setIsFirstVisit(true);
      localStorage.setItem('paramount_wood_co_visited', 'true');
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    setShowcaseItem(null)
  }, [pathname]);

  useEffect(() => {
    if (showCaseItem) {
      dispatch(updateHeader('dark'))
      document.body.classList.add('modal');
      const showcaseEl = document.querySelector('.portfolio-showcase');
      showcaseEl.scrollTo({ top: 0, behavior: 'smooth' })
    } else {
      dispatch(updateHeader('light'))
      document.body.classList.remove('modal')
    }
  }, [showCaseItem, dispatch])

  const handleClosePopup = () => {
    setIsFirstVisit(false);
  };

  return (
    <HelmetProvider>
      <div>
        {data &&
          <>
            <Splash />
            
            <Header announcement={data.announcement} />
            <Routes>
              <Route path="/" element={<Home carousel={data.carousel} />} />
              <Route path="/portfolio" element={<Portfolio setShowcaseItem={setShowcaseItem} />} />
              <Route path="/shop" element={<Store />} />
              <Route path="/slabs" element={<Slabs type={"slabs"} />} />
              <Route path="/bases" element={<Bases type={"bases"} />} />
              <Route path="/our-story" element={<OurStory />} />
              <Route path='/jeffrey-greene' element={<JeffreyGreene />} />
              <Route path="/products/:id/:variantId?" element={<Product type="products" />} />
              <Route path="/slabs/:id/:variantId?" element={<Product type="slabs" />} />
              <Route path="/bases/:id/:variantId?" element={<Product type="bases" />} />
              <Route path="/divergence/:id/:variantId?" element={<Divergence />} />
              <Route path="/custom-design" element={<Custom />} />
              <Route path="/check-out" element={<CheckOut />} />
              <Route path="/table-builder" element={<TableBuilder />} />
              <Route path="/cart" element={<Cart />} />
              <Route path="/faqs" element={<FAQs />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/additional-services" element={<AdditionalServices />} />
              <Route path="/wishlist" element={<WishList />} />
              <Route path="/media" element={<Media media={data?.media} />} />
              <Route path="/media/:id" element={<MediaPost media={data?.media} />} />
            </Routes>

            <Showcase showCaseItem={showCaseItem} setShowcaseItem={setShowcaseItem} />

            <CartPreview />
            <Footer />

            <Arrow />

            {isFirstVisit && <SubscribeModal onClose={handleClosePopup} />}
          </>
        }
      </div>
    </HelmetProvider>
  );
}

export default App;
