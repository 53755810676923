import { useEffect } from 'react';
import './styles.scss';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { updateHeader } from '../../slices/header-slice';
import { formatDate } from '../../utility/utils';
import SEO from '../../components/seo';


const Media = ({ media }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(updateHeader('light'));
    }, [dispatch]);

    const onMediaPostClick = (post) => {
        navigate(`/media/${post.id}`);
    };

    return (
        <div className="media-page">
            <SEO
                title="Paramount Wood Co."
                description="A collection of historic news and insights from Paramount Woof Co. descending in chronological order.If you wish to receive these articles in real time, please subscribe to our newsletter for regular updates and happenings from the studio."
                name="Paramount Wood Co."
                type="website"
                url="https://paramountwoodco.com/media"
            />

            <div className="media-intro">
                <h1>Media</h1>
                <p>A collection of historic news and insights from Paramount Woof Co. descending in chronological order.If you wish to receive these articles in real time, please subscribe to our newsletter for regular updates and happenings from the studio.</p>
            </div>

            <div className='content'>
                <div className='media-content'>
                    {media && media.map((post, i) => (
                        <div key={i} className='media-post'>
                            <div className='post-info'>
                                <div>
                                    <span className='date'>{formatDate(post.date)}</span>
                                    <h2>{post.title}</h2>
                                    <div className='html-text' dangerouslySetInnerHTML={{ __html: post?.content }} />
                                </div>
                                <button className="cta gold-fill" onClick={() => onMediaPostClick(post)}>View More</button>
                            </div>

                            <div>
                                <img alt='' src={`${process.env.REACT_APP_WEBSITE_API}/api/images/optimize?filePath=images/media/${post.images[0]?.fileName}&width=350&height=230&quality=99&format=webp`} />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};


export default Media;
