import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { removeWishListItem } from "../../slices/app-slice";

import './styles.scss'
import { updateHeader } from "../../slices/header-slice";
import { useNavigate } from "react-router-dom";

const WishList = () => {
    const wishListItems = useSelector(state => state.app.wishList);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(updateHeader('light'))
    }, [dispatch]);

    const removeItem = (id) => {
        dispatch(removeWishListItem(id))
    }

    const inquire = useCallback(() => {
        navigate('/contact', { state: { wishlist: wishListItems } });
    }, [navigate, wishListItems])

    return (
        <div className="cart-page">
            <div className="cart-page-content">
                <h1>Your Wish List</h1>
                <div>
                    <div className="cart-page-items">
                        <ul>
                            {wishListItems?.length === 0 &&
                                <p className="feedback">It looks like your wish list is empty.</p>
                            }

                            {wishListItems?.map((item, i) => {
                                return <li key={i}>
                                    <div className="item-img">
                                        <img alt='' width='135' src={`${process.env.REACT_APP_WEBSITE_API}/api/images/optimize?filePath=images/slabs/${item?.item?.images[0].fileName}&width=150&height=150&quality=80&format=webp`} />
                                        <p className="remove" onClick={() => removeItem(item?.item?.id)}>X Remove</p>
                                    </div>
                                    <div className="item-desc">
                                        <p>{item?.item?.title}</p>
                                    </div>
                                </li>
                            })}
                        </ul>
                    </div>
                    <div className="cart-page-summary">
                        <div>
                            <h2>Wish list</h2>
                            
                            <p>To inquire about all your wishlisted items, please click the button below.</p>

                            <button disabled={wishListItems?.length === 0} onClick={() => inquire()} className="cta gold-fill">Inquire</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WishList;