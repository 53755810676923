import { useCallback, useEffect, useRef, useState } from 'react';
import './styles.scss';
import { useSelector, useDispatch } from 'react-redux'
import { Plus } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import { updateHeader } from '../../slices/header-slice';
import { LazyLoadedGroup } from '../../components/lazy-loaded-group';
import { groupArrayInChunks } from '../../utility/utils';
import PriceFilterSlider from '../../components/price-filter-slider';

const Store = () => {
    const navigate = useNavigate();
    const products = useSelector((state) => state.app.products);
    const divergenceDiningTables = useSelector((state) => state.app.divergenceDiningTables);
    const dispatch = useDispatch();
    const [filters, setFilters] = useState([]);
    const [activeFilter, setActiveFilter] = useState(null);
    const [filterResults, setFilterResults] = useState([]);
    const [filterOpen, setFilterOpen] = useState(false);
    const [priceRange, setPriceRange] = useState([0, Infinity]);
    const sliderRef = useRef(null);

    const minPrice = Math.min(...products.map(p => Math.floor(p.price)));
    const maxPrice = Math.max(...products.map(p => p.price));

    useEffect(() => {
        dispatch(updateHeader('light'));
    
        if (products) {
            let updatedProducts = [...products];  // Copy the original products array

            // if (divergenceDiningTables) {
            //     updatedProducts.push(divergenceDiningTables);
            // }

            const categories = updatedProducts.map(x => x.category);
            const unique = [...new Set(categories)];  // Find unique categories

            setFilters(unique);
            setFilterResults(groupArrayInChunks(updatedProducts, 8));

        }
    }, [dispatch, products, divergenceDiningTables]);  // Add divergenceDiningTables as a dependency

    const filterProducts = useCallback(() => {
        let updatedProducts = [...products];
        let filteredProducts = updatedProducts;


        if (divergenceDiningTables) {
            // Combine products and divergenceDiningTables
            filteredProducts.push(divergenceDiningTables);
        }

        if (activeFilter) {
            filteredProducts = filteredProducts.filter((product) => product.category === activeFilter);
        }

        filteredProducts = filteredProducts.filter(
            (product) => (product.type === "DiningTable") || (product.price >= priceRange[0] && product.price <= priceRange[1])
        );


        setFilterResults(groupArrayInChunks(filteredProducts, 8));
    }, [activeFilter, priceRange, products, divergenceDiningTables]);

    useEffect(() => {
        filterProducts();
    }, [filterProducts]);

    const onProductClick = (item) => {
        //navigate(`/products/${item.id}`);

        navigate({
            pathname: `/products/${item.id}`,
            search: `?title=${item.title}`,
        })
    };

    const handlePriceChange = (range) => {
        const [min, max] = range;

        if (activeFilter) {
            const newFilteredProducts = products.filter(product =>
                product.category === activeFilter &&
                product.price >= min &&
                product.price <= max
            );
            setFilterResults(groupArrayInChunks(newFilteredProducts, 8));
        } else {
            const newFilteredProducts = products.filter(product =>
                product.price >= min &&
                product.price <= max
            );
            setFilterResults(groupArrayInChunks(newFilteredProducts, 8));
        }

        setPriceRange(range);
    };

    const clearFilter = useCallback(() => {
        setActiveFilter(null);
        setFilterResults(groupArrayInChunks(products, 8));
        if (sliderRef.current) {
            sliderRef.current.resetSlider();
        }
    }, [products]);

    return (
        <div className="store-page">
            <div className="store-intro">
                <h1>From our studio, to your home.</h1>
                <p>Welcome to our exclusive range of Paramount pieces available to purchase today. An opportunity to purchase your very own piece of the Paramount portfolio, our collection ranges from small tables, home accessories and merchandise. All products and works are made right here in Bucks County PA. </p>
            </div>

            <div className='tools-wrap'>
                <div className='tools'>
                    <div className='filter' onClick={() => setFilterOpen(!filterOpen)}>
                        {filterOpen
                            ? <span>Close</span>
                            : <span>Filters <Plus /></span>
                        }
                    </div>
                    <div className='sort'></div>
                </div>
                {filterOpen &&
                    <div className={`filter-content ${filterOpen ? "open" : ""}`}>
                        <div className='filter-options'>
                            {filters.map((filter, i) => (
                                <div key={i}>
                                    <label className='custom-radio'>
                                        <input type='radio' name="filter" checked={filter === activeFilter} onChange={() => setActiveFilter(filter)} value={filter} />
                                        <span className="checkmark"></span>
                                        {filter}
                                    </label>
                                </div>
                            ))}
                        </div>
                        <PriceFilterSlider
                            ref={sliderRef}
                            minPrice={minPrice}
                            maxPrice={maxPrice}
                            onFilterChange={handlePriceChange}
                        />
                        <div className='filter-buttons'>
                            <button className='cta dark' onClick={() => clearFilter()}>Clear</button>
                        </div>
                    </div>
                }
            </div>

            <div className='lazy-load'>
                {filterResults?.map((group, i) => (
                    <LazyLoadedGroup
                        key={i}
                        group={group}
                        index={i}
                        type={'products'}
                        onProductClick={onProductClick}
                    />
                ))}
            </div>
        </div>
    );
};

export default Store;
