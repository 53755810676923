import { useEffect, useState, useCallback } from 'react';
import './styles.scss';
import { useSelector, useDispatch } from 'react-redux';
import { Plus } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import { updateHeader } from '../../slices/header-slice';

import baseFilter from '../../assets/images/structures/base_filter.jpg';
import baseFilterDining from '../../assets/images/structures/base_filter_dining_table.png';
import baseFilterCoffee from '../../assets/images/structures/base_filter_coffee_table.png';
import baseFilterConsole from '../../assets/images/structures/base_filter_console_table.png';
import { LazyLoadedGroup } from '../../components/lazy-loaded-group';
import { groupArrayInChunks } from '../../utility/utils';

const Bases = ({ type }) => {
    const navigate = useNavigate();
    const bases = useSelector((state) => state.app.bases);
    const dispatch = useDispatch();
    const [filters, setFilters] = useState([]);
    const [activeFilter, setActiveFilter] = useState(null);
    const [activeBaseFilter, setActiveBaseFilter] = useState(null);
    const [filterResults, setFilterResults] = useState([]);
    const [filterOpen, setFilterOpen] = useState(false);

    useEffect(() => {
        dispatch(updateHeader('light'));

        if (bases) {
            const categories = bases.filter(b => b.material !== null).map(x => x.material);
            const unique = categories.filter((c, index) => categories.indexOf(c) === index);
            setFilters(unique);
            setFilterResults(groupArrayInChunks(bases, 8));
        }

    }, [dispatch, bases]);

    useEffect(() => {
        if (activeBaseFilter) {
            let result = bases.filter(x => x.type === activeBaseFilter);
            if (activeFilter) {
                result = result.filter(x => x.material === activeFilter);
            }

            setFilterResults(groupArrayInChunks(result, 8));
        } else {
            if (activeFilter) {
                const result = bases.filter(x => x.filter === activeFilter);
                setFilterResults(groupArrayInChunks(result, 8));
            }
        }

    }, [activeFilter, activeBaseFilter, bases]);

    const clearFilter = useCallback(() => {
        setActiveFilter(null);
        setActiveBaseFilter(null);
        setFilterResults(groupArrayInChunks(bases, 8));
    }, [bases]);

    const onProductClick = (item) => {
        //navigate(`/bases/${item.id}`);

        navigate({
            pathname: `/bases/${item.id}`,
            search: `?title=${item.title}`,
        })
    };

    return (
        <div className="bases-page">
            <div className="store-intro">
                <h1>Base Designs.</h1>
                <p>We provide a variety of pre-made Base Designs listed below...</p>
            </div>

            <div className="base-type-filter">
                <div className="base-filter info">
                    <img src={baseFilter} alt="base filter" />
                    <div>
                        <h2>Base Type</h2>
                        <p>Select to filter design choice.</p>
                    </div>
                </div>
                <div className={`base-filter ${activeBaseFilter === "Dining Table" ? "active" : ""}`} onClick={() => setActiveBaseFilter("Dining Table")}>
                    <img src={baseFilterDining} alt="Dining Tables" />
                    <div>
                        <p>Dining Table</p>
                    </div>
                </div>
                <div className={`base-filter ${activeBaseFilter === "Coffee Table" ? "active" : ""}`} onClick={() => setActiveBaseFilter("Coffee Table")}>
                    <img src={baseFilterCoffee} alt="Coffee Tables" />
                    <div>
                        <p>Coffee Table</p>
                    </div>
                </div>
                <div className={`base-filter ${activeBaseFilter === "Console Table" ? "active" : ""}`} onClick={() => setActiveBaseFilter("Console Table")}>
                    <img src={baseFilterConsole} alt="Console Tables" />
                    <div>
                        <p>Console Table</p>
                    </div>
                </div>
            </div>

            <div className="tools-wrap">
                <div className="tools">
                    <div className="filter" onClick={() => setFilterOpen(!filterOpen)}>
                        {filterOpen ? <span>Close</span> : <span>Filters <Plus /></span>}
                    </div>
                    <div className="sort"></div>
                </div>
                {filterOpen && (
                    <div className={`filter-content ${filterOpen ? "open" : ""}`}>
                        <div className="filter-options">
                            {filters.map((filter, i) => (
                                <div key={i}>
                                    <label className="custom-radio">
                                        <input type="radio" name="filter" checked={filter === activeFilter} onChange={() => setActiveFilter(filter)} value={filter} />
                                        <span className="checkmark"></span>
                                        {filter}
                                    </label>
                                </div>
                            ))}
                        </div>
                        <div className="filter-buttons">
                            <button className="cta dark" onClick={clearFilter}>Clear</button>
                        </div>
                    </div>
                )}
            </div>

            <div className='lazy-load'>
                {filterResults &&
                    filterResults.map((group, i) => (
                        <LazyLoadedGroup
                            key={i}
                            group={group}
                            index={i}
                            type={type}
                            onProductClick={onProductClick}
                        />
                    ))}
            </div>
        </div>
    );
};



export default Bases;
