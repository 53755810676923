
import './styles.scss';
import React, { useState, useEffect } from 'react';

const Announcement = ({announcement}) => {

    return (
        <div className={`announcement gradient-animation`}>
            <div className='html-text' dangerouslySetInnerHTML={{ __html: announcement?.content }} />
        </div>
    )
}

export default Announcement;