import { useDispatch, useSelector } from "react-redux";
import { motion } from "framer-motion";

import './styles.scss'
import { useNavigate } from "react-router-dom";
import { CheckCircle, Heart } from "react-feather";
import { showPreview, updateWishList } from "../../slices/app-slice";
import { formatPrice } from "../../utility/utils";

const CartPreview = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const showOverlay = useSelector(state => state.app.showPreview)
    const quickAdd = useSelector(state => state.app.quickAddItem)

    const closeOverlay = () => {
        dispatch(showPreview(false))
    }

    const handleNav = (route) => {
        dispatch(showPreview(false))
        navigate(route)
    }

    const addToWishList = () => {
        dispatch(updateWishList({
            item: quickAdd.item,
            type: "slab"
        }))

    }

    const onReadMoreClick = () => {
        dispatch(showPreview(false))
        navigate(`/slabs/${quickAdd.item.id}`);
    }

    return (
        <>
            {showOverlay &&
                <>
                    <div onClick={() => closeOverlay()} className="cart-preview-overlay"></div>
                    {quickAdd && (quickAdd.product) && quickAdd.type !== "quickView" &&
                        <motion.div
                            layout
                            initial={{ y: 100, opacity: 0, bottom: 30, right: 30, position: "fixed", zIndex: 11 }}
                            animate={{ y: 0, opacity: 1, bottom: 30, right: 30, position: "fixed", zIndex: 11 }}
                            exit={{ y: 100, opacity: 0, bottom: 30, right: 30, position: "fixed", zIndex: 11 }}
                            transition={{ duration: 0.2 }}
                            className="cart-preview"
                        >
                            <div className="cart-preview-content">
                                <div className="cart-preview-message">
                                    <CheckCircle />
                                    <p>Added to Cart</p>

                                </div>
                                <div className="cart-preview-item">
                                    <img alt='' width='135' src={`${process.env.REACT_APP_WEBSITE_API}/api/images/optimize?filePath=images/products/${quickAdd?.product?.images[0].fileName}&width=150&height=150&quality=80&format=webp`} />

                                    <div>
                                        <p>{quickAdd?.product?.title}</p>

                                        { !quickAdd?.product?.salePrice 
                                            ? <p>{formatPrice(quickAdd?.total)}</p>
                                            : <><p className="strike-through">{formatPrice(quickAdd?.total)}</p><p className="sale-price">{formatPrice(quickAdd?.product?.salePrice)}</p></>
                                        }
                                    </div>

                                </div>
                                <div className="cart-preview-actions">
                                    <button className="cta gold-fill" onClick={() => handleNav("/cart")}>View Cart</button>
                                    <button className="cta dark" onClick={() => handleNav("/check-out")}>Checkout</button>
                                </div>
                            </div>
                        </motion.div>

                    }

                    {quickAdd && quickAdd.item && quickAdd.type !== "quickView" &&
                        <motion.div
                            layout
                            initial={{ y: 100, opacity: 0, bottom: 30, right: 30, position: "fixed", zIndex: 11 }}
                            animate={{ y: 0, opacity: 1, bottom: 30, right: 30, position: "fixed", zIndex: 11 }}
                            exit={{ y: 100, opacity: 0, bottom: 30, right: 30, position: "fixed", zIndex: 11 }}
                            transition={{ duration: 0.2 }}
                             className="cart-preview"
                        >
                            <div className="wishlist-preview-content">
                                <div className="wishlist-preview-message">
                                    <Heart />
                                    <p>Added to Wishlist</p>

                                </div>
                                <div className="wishlist-preview-item">
                                    <img alt='' width='135' src={`${process.env.REACT_APP_WEBSITE_API}/api/images/optimize?filePath=images/slabs/${quickAdd?.item?.images[0].fileName}&width=150&height=150&quality=80&format=webp`} />

                                    <div>
                                        <p>{quickAdd?.item?.title}</p>
                                    </div>

                                </div>
                                <div className="wishlist-preview-actions">
                                    <button className="cta gold-fill" onClick={() => handleNav("/wishlist")}>View Wishlist</button>
                                </div>
                            </div>
                        </motion.div>

                    }

                    {quickAdd && quickAdd.item && quickAdd.type === "quickView" &&
                        <motion.div
                            layout
                            initial={{ y: 100, opacity: 0, bottom: 30, right: 30, position: "fixed", zIndex: 11 }}
                            animate={{ y: 0, opacity: 1, bottom: 30, right: 30, position: "fixed", zIndex: 11 }}
                            exit={{ y: 100, opacity: 0, bottom: 30, right: 30, position: "fixed", zIndex: 11 }}
                            transition={{ duration: 0.2 }}
                             className="cart-preview"
                        >
                            <div className="quick-preview-content">
                                <div className="quick-preview-message">
                                    <h1>{quickAdd?.item?.title}</h1>
                                </div>
                                <div className="quick-preview-item">

                                    <div>
                                        <h2>Starting Price from {formatPrice(quickAdd?.item?.startingPrice)}</h2>
                                        <h2>Seats up to {quickAdd?.item?.seats} people</h2>
                                        <h2>Origin: {quickAdd?.item?.origin}</h2>
                                        {/* <h2>Grain: {quickAdd?.item?.grain}</h2> */}
                                    </div>

                                </div>
                                <div className="quick-preview-actions">
                                    <button className="cta gold-fill" onClick={() => onReadMoreClick()}>More info</button>
                                    <button className="cta dark" onClick={() => addToWishList()}>Add to Wish list</button>
                                </div>
                            </div>
                        </motion.div>

                    }
                </>
            }
        </>
    )
}

export default CartPreview;