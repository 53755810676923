import { useEffect, useRef } from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom";
import { updateHeader } from "../../slices/header-slice";
import { useInView } from "framer-motion";

import Animate from '../../components/animate';

import './styles.scss';

const AdditionalServices = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const ref = useRef(null);
    const isInView = useInView(ref, { once: false, amount: 0.1 });

    useEffect(() => {
        if (isInView) {
            dispatch(updateHeader('transparent'))

        } else {
            dispatch(updateHeader('dark'))
        }
    }, [isInView, dispatch]);

    useEffect(() => {
        dispatch(updateHeader('transparent'))
    }, [dispatch]);

    return (
        <div className="additional-services">
            <div ref={ref} className="page-hero">
                <div className="hero-content">
                    <h1>Additional Services</h1>
                    <p>Workshop services like hand sanding, planing, and more.</p>
                </div>
            </div>

            <Animate>
                <div className="services-info">
                    <div className="services">
                        <div className="service">
                            <h2>CNC Flattening</h2>
                            <p>Avid CNC Machine: 16' x 5'</p>
                            <p>$150 per hour - No Resin</p>
                            <p>$250 per hour - Resin</p>
                        </div>
                        <div className="service">
                            <h2>Belt Sanding</h2>
                            <p>Wide Belt Sander is 52” wide</p>
                            <p>Up to 180 grit. Minimum of 4 square feet.</p>
                            <p>$40 per square foot - No Resin</p>
                            <p>$75 per square foot - Resin</p>
                        </div>
                        <div className="service">
                            <h2>Hourly Shop Rate</h2>
                            <p>Our general shop hourly rate is $300</p>
                            <p>per hour for services like hand sanding,</p>
                            <p>planing, and more.</p>
                        </div>
                    </div>
                    <button className="cta gold-fill" onClick={() => navigate("/contact")}>Request a quote</button>
                </div>
            </Animate>
        </div>
    )
}

export default AdditionalServices