import { useEffect, useRef, useState } from "react"
import { useDispatch } from "react-redux"
import { updateHeader } from "../../slices/header-slice";
import { useInView } from "framer-motion";
import { customSubmission } from "../../api";

import Animate from '../../components/animate';

import './styles.scss';
import FeedbackModal from "../../components/feedback-modal";

const Custom = () => {
    const dispatch = useDispatch();

    const ref = useRef(null);
    const isInView = useInView(ref, { once: false, amount: 0.1 });

    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsloading] = useState(false);
    const [images, setImages] = useState([]);
    const [fileUploadText, setFileUploadText] = useState("Attach and upload an inspiration image");

    const [state, setState] = useState({
        firstName: "",
        lastName: "",
        email: "",
        type: "",
        species: "",
        budget: ""
    });

    useEffect(() => {
        if (isInView) {
            dispatch(updateHeader('transparent'))

        } else {
            dispatch(updateHeader('dark'))
        }
    }, [isInView, dispatch]);

    useEffect(() => {
        dispatch(updateHeader('transparent'))
    }, [dispatch]);

    const submitContact = (e) => {
        e.preventDefault();
        setIsloading(true)

        const formData = new FormData();
        images.forEach((image, index) => {
            formData.append(`image${index + 1}`, image);
        });

        Object.keys(state).forEach(key => {
            formData.append(key, state[key]);
        });

        customSubmission(formData).then((res) => {
            if (res.status === 200 || res.status === 204) {
                setIsSuccess(true);
            } else {
                setIsError(true);
            }

            setIsloading(false)
        }).catch((error) => {
            setIsError(true);
            setIsloading(false)
        });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setState((prev) => ({ ...prev, [name]: value }));
    };

    const onFeedbackClose = () => {
        setIsSuccess(false)
        setIsError(false)
    }

    const handleFileChange = (e) => {
        setImages([...e.target.files]);
        setFileUploadText(`${e.target.files.length} ${e.target.files.length > 1 ? "images" : "image"} selected. Thank you!`)
    };

    return (
        <>
            <div className="custom-design">
                <div ref={ref} className="page-hero">
                    <div className="hero-content">
                        <h1>Create your legacy piece</h1>
                        <p>With Paramount Wood Co.</p>
                    </div>
                </div>

                <Animate>
                    <div className="custom-info">
                        <h2>Commission a bespoke heirloom piece, expertly crafted and designed to last generations. Work collaboratively with one of our highly skilled woodworkers, to create a truly unique piece of furniture from initial brief right up to tailored installation.</h2>
                        <p>Register your interest below</p>
                        <div className="line"></div>
                    </div>
                </Animate>

                <Animate>
                    <div className='content custom-submission'>
                        <h2>Submission form</h2>
                        <form>
                            <div>
                                <div className="col">
                                    <input type='text' name="firstName" defaultValue="" onChange={(e) => handleInputChange(e)} placeholder='First Name' />
                                    <input type='text' name="lastName" defaultValue="" onChange={(e) => handleInputChange(e)} placeholder='Last Name' />
                                    <input type='email' name="email" defaultValue="" onChange={(e) => handleInputChange(e)} placeholder='Email' />
                                    <input type='phone' name="phone" defaultValue="" onChange={(e) => handleInputChange(e)} placeholder='Phone' />
                                    <input type='text' name="location" defaultValue="" onChange={(e) => handleInputChange(e)} placeholder='Location / City' />
                                </div>
                                <div className="col">
                                    <textarea type='text' name="type" defaultValue="" onChange={(e) => handleInputChange(e)} placeholder='Describe what you are looking for'></textarea>
                                    <input type='text' name="species" defaultValue="" onChange={(e) => handleInputChange(e)} placeholder='Preferred Wood Species' />
                                    <input type='text' name="budget" defaultValue="" onChange={(e) => handleInputChange(e)} placeholder='Budget $' />
                                    <div>
                                        <label className="custom-file-input" htmlFor="file-input">{fileUploadText}</label>
                                        <input id="file-input" name="file" type="file" max={5} min={1} multiple onChange={handleFileChange} />
                                    </div>
                                </div>
                            </div>
                            <button className={`cta gold-fill ${isLoading ? "loading" : ""}`} onClick={(e) => submitContact(e)}>
                                <div className="loader"></div>
                                <span>Submit</span>
                            </button>


                        </form>
                    </div>
                </Animate>


            </div>

            {isSuccess &&
                <FeedbackModal status={"success"} type={"contact"} title={"Email Sent"} message={"Thank you for your enquiry, you will be contacted soon by one of the Paramount Wood Co. team"} onClose={onFeedbackClose} label={"Close"} />
            }

            {isError &&
                <FeedbackModal status={"error"} type={"contact"} title={"Email failed to send"} message={"Sorry, something went wrong while trying to send your email. Please try again."} onClose={onFeedbackClose} label={"Close"} />
            }
        </>

    )
}

export default Custom