import { motion } from "framer-motion";

import './styles.scss'
import { CheckCircle, AlertCircle } from "react-feather";

const FeedbackModal = ({ status, type, title, message, onClose, label, children }) => {
    const closeOverlay = () => {
        onClose()
    }

    return (
        <>
            <div onClick={() => closeOverlay()} className="feedback-modal-overlay"></div>
            <motion.div
                layout
                initial={{ y: 100, opacity: 0, top: "50%", left: "50%", transform: "translate(-50%,-50%)", position: "fixed", zIndex: 11 }}
                animate={{ y: 0, opacity: 1, top: "50%", left: "50%", transform: "translate(-50%,-50%)", position: "fixed", zIndex: 11 }}
                exit={{ y: 100, opacity: 0, top: "50%", right: "50%", transform: "translate(-50%,-50%)", position: "fixed", zIndex: 11 }}
                transition={{ duration: 0.2 }}
            >
                <div className="feedback-modal-content">
                    {type !== "info" &&
                        <div className={`feedback-modal-status ${status === "error" ? "error" : ""}`}>
                            {status === "success"
                                ? <> <CheckCircle /><p>Success</p></>
                                : <> <AlertCircle /><p>Error</p></>
                            }
                        </div>
                    }
                    <div className="feedback-modal-message">
                        <div>
                            <h2>{title}</h2>
                            <p>{message}</p>
                            <div className="children">{children}</div>
                        </div>                     
                    </div>
                    <div className="feedback-modal-actions">
                        <button className="cta gold-fill" onClick={() => closeOverlay()}>{label}</button>
                    </div>
                </div>
            </motion.div>
        </>
    )
}

export default FeedbackModal;