import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import { useInView } from "framer-motion";
import { useSelector, useDispatch } from 'react-redux'
import { updateHeader } from '../../slices/header-slice';
import { Navigation, Pagination, Autoplay, EffectFade } from 'swiper/modules';

import Item from '../../components/item';
import Animate from '../../components/animate';

import './styles.scss';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';

import video from '../../assets/videos/home.mp4';
import { renderRating } from '../../utility/utils';
import SEO from '../../components/seo';

const Home = ({ carousel }) => {
    const ref = useRef(null);
    const isInView = useInView(ref, { once: false, amount: 0.1 });
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [animateTitle, setAnimateTitle] = useState(true);
    const [activeSlide, setActiveSlide] = useState(0)

    const products = useSelector((state) => state.app.products);
    const testimonials = useSelector((state) => state.app.testimonials);

    useEffect(() => {
        if (isInView) {
            dispatch(updateHeader('transparent'))

        } else {
            dispatch(updateHeader('light'))
        }
    }, [isInView, dispatch]);

    const onProductClick = useCallback((item) => {
        navigate({
            pathname: `/products/${item.id}`,
            search: `?title=${item.title}`,
        })
    }, [navigate])

    const handleAnimation = useCallback((swiper) => {

        if (swiper) {
            setActiveSlide(swiper.activeIndex)
        }

        setAnimateTitle(false)

        const t = setTimeout(() => {
            setAnimateTitle(true);
            clearTimeout(t)
        }, 400)
    }, [])

    const pagination = {
        clickable: true,
        renderBullet: function (index, className) {
            return '<span class="' + className + '">' + (index + 1) + '</span>';
        },
    };

    const formatLink = (url) => {
        let newUrl = window.decodeURIComponent(url);
        newUrl = newUrl.trim().replace(/\s/g, "");

        if (/^(:\/\/)/.test(newUrl)) {
            return `http${newUrl}`;
        }
        if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
            return `http://${newUrl}`;
        }

        return newUrl;
    }

    return (
        <div className="home">
            <SEO
                title="Paramount Wood Co."
                description="We make high end woodwork through dedicated craftsmanship, using only the rarest and finest materials in the world. We have a deep respect for the wood we use, and each piece is built on a unique story, but never one of deforestation. This is our promise."
                name="Paramount Wood Co."
                type="website"
                url="https://paramountwoodco.com"
            />

            <div ref={ref} className="home-gallery">

                <Swiper
                    slidesPerView={1}
                    modules={[Pagination, Autoplay, EffectFade]}
                    navigation={false}
                    pagination={pagination}
                    onSlideChange={(swiper) => handleAnimation(swiper)}
                    effect='fade'
                    fadeEffect={{
                        crossFade: true
                    }}
                    speed={1500}
                    autoplay={{
                        delay: 9000,
                        disableOnInteraction: true,
                    }}
                >

                    {carousel && carousel.map((item, i) => (
                        <Fragment key={i}>
                            {item?.fileName?.substring(item?.fileName?.lastIndexOf(".")) === ".mp4" ?

                                <SwiperSlide key={i}>
                                    <div className='page-hero video'>
                                        <video autoPlay muted loop src={`${process.env.REACT_APP_WEBSITE_API}/images/carousel/${item.fileName}`} type="video/mp4" width="100%" />
                                        {animateTitle && activeSlide === i &&
                                            <Animate>
                                                <div className="hero-content">
                                                    {item.title &&
                                                        <h1>{item.title}</h1>
                                                    }

                                                    {item.subtitle &&
                                                        <p>{item.subtitle}</p>
                                                    }

                                                    {item.buttonLink && item.buttonText &&
                                                        <>
                                                            {item.isExternal ? <a href={formatLink(item.buttonLink)} target="_blank" rel="noopener noreferrer" className="cta">{item.buttonText}</a> : <Link to={item.buttonLink} className="cta">{item.buttonText}</Link>}
                                                        </>
                                                    }
                                                </div>
                                            </Animate>
                                        }
                                    </div>
                                </SwiperSlide>

                                : <SwiperSlide key={i}>
                                    <div className='page-hero' style={{ backgroundImage: `url("${`${process.env.REACT_APP_WEBSITE_API}/api/images/optimize?filePath=images/carousel/${item.fileName}&width=1920&height=1080&quality=99&format=webp`}")` }}>
                                        {animateTitle && activeSlide === i &&
                                            <Animate>
                                                <div className="hero-content">
                                                    {item.title &&
                                                        <h1>{item.title}</h1>
                                                    }

                                                    {item.subtitle &&
                                                        <p>{item.subtitle}</p>
                                                    }

                                                    {item.buttonLink && item.buttonText &&
                                                        <>
                                                            {item.isExternal ? <a href={formatLink(item.buttonLink)} target="_blank" rel="noopener noreferrer" className="cta">{item.buttonText}</a> : <Link to={item.buttonLink} className="cta">{item.buttonText}</Link>}
                                                        </>
                                                    }
                                                </div>
                                            </Animate>
                                        }
                                    </div>
                                </SwiperSlide>
                            }
                        </Fragment>

                    ))}

                    {(!carousel || !carousel.length) &&
                        <>
                            <SwiperSlide>
                                <div className='page-hero builder'>
                                    <video autoPlay muted loop src={video} type="video/mp4" width="100%" />
                                    {animateTitle && activeSlide === 0 &&
                                        <Animate>
                                            <div className="hero-content">
                                                <h1>Table Builder</h1>
                                                <p>Build your own table through our brand new design configurator. </p>
                                                <Link to="table-builder" className="cta">Coming Soon</Link>
                                            </div>
                                        </Animate>
                                    }
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='page-hero store'>
                                    {animateTitle && activeSlide === 1 &&
                                        <Animate>
                                            <div className="hero-content">
                                                <h1>Online Store</h1>
                                                <p>Hand crafted one at a time by our boutique team of skilled artisans.</p>
                                                <Link to="/shop" className="cta">Shop now</Link>
                                            </div>
                                        </Animate>
                                    }
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='page-hero commission'>
                                    {animateTitle && activeSlide === 2 &&
                                        <Animate>
                                            <div className="hero-content">
                                                <h1>Commission a piece</h1>
                                                <p>Work with our highly skilled designers and crafts people, to design your own unique heirloom piece.</p>
                                                <Link className="cta">Get in touch</Link>
                                            </div>
                                        </Animate>
                                    }
                                </div>
                            </SwiperSlide>

                        </>

                    }
                </Swiper>
            </div>

            <Animate>
                <div className="home-products content">
                    <h1>Our Products</h1>

                    {products &&
                        <Swiper
                            spaceBetween={10}
                            slidesPerView={window.innerWidth > 768 ? 4 : 1}
                            modules={[Navigation, Pagination]}
                            navigation={true}
                            effect=''
                            pagination={{
                                type: 'progressbar',
                            }}
                        >
                            <SwiperSlide>
                                <Link to='/shop'>
                                    <div className='home-swiper-product'>
                                        <div className='home-swiper-product-intro'>
                                            <div>
                                                <h3>Craftsmanship</h3>
                                                <p>A curated selection of artisan pieces.</p>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </SwiperSlide>

                            {products?.map((product, i) => (
                                <SwiperSlide key={i}>
                                    <div className='home-swiper-product items'>
                                        <Item item={product} index={i} type='products' setItem={onProductClick} />
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    }
                </div>
            </Animate>



            {/* <Animate>
                <div className="page-hero jeffrey animate-in-view">
                    <div className="hero-content">
                        <h1>Jeffrey Greene acquisition</h1>
                        <p>A new chapter – Two studios with a united vision.</p>
                        <Link to="/jeffrey-greene" className="cta">Learn more</Link>
                    </div>
                </div>
            </Animate> */}

            <Animate>
                <div className="testimonials">
                    <p>What our customers say</p>

                    <Swiper
                        modules={[Navigation, Pagination, EffectFade]}
                        navigation={true}
                        effect='fade'
                        fadeEffect={{
                            crossFade: true
                        }}
                        speed={1000}
                        autoplay={{
                            delay: 5000,
                            disableOnInteraction: true,
                        }}
                    >
                        {testimonials?.map((testimonial, i) => (
                            <SwiperSlide key={i}>
                                <div className='swiper-testimonial'>
                                    <h2>"{testimonial?.message}"</h2>
                                    <div className='stars'>{renderRating(testimonial.rating)}</div>
                                    <p className="author">{testimonial.author}</p>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </Animate>

            <Animate>
                <div className="home-projects animate-in-view">
                    <div className='content'>
                        <div className="project">
                            <div className='project-img'></div>
                            <div className='project-details'>
                                <h3>Custom Design</h3>
                                <p>Work with our highly skilled designers and crafts people to design your own unique heirloom piece.</p>
                                <Link to="/custom-design" className="cta dark">Learn more</Link>
                            </div>
                        </div>

                        <div className="project">
                            <div className='project-img'></div>
                            <div className='project-details'>
                                <h3>Our Projects</h3>
                                <p>Showcasing a range of unique and beautifully-designed timeless pieces from our studio in Bucks County, PA.</p>
                                <Link to="/portfolio" className="cta dark">Learn more</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </Animate>

            <Animate>
                <div className="page-hero art animate-in-view">
                    <div className="hero-content">
                        <h1>We make Art not Furniture</h1>
                        <p>Each piece is built on a unique, lasting story and designed to last for generations.</p>
                        <Link to="/contact" className="cta">Build now</Link>
                    </div>
                </div>
            </Animate>

            {/* <Animate>
                <div className='content home-account animate-in-view'>
                    <div className='col'></div>
                    <div className='col'>
                        <h2>Create account</h2>
                        <p>Sign up to our members area and keep track of your orders and the latest news from our studio.</p>
                        <form>
                            <input type='text' placeholder='First Name' />
                            <input type='text' placeholder='Last Name' />
                            <input type='email' placeholder='Email' />
                            <button className='cta' type='submit'>Submit</button>
                        </form>
                    </div>
                </div>
            </Animate> */}


        </div>
    )
}

export default Home;