import { useEffect, useState } from 'react';
import './styles.scss';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { updateHeader } from '../../slices/header-slice';
import { formatDate } from '../../utility/utils';
import SEO from '../../components/seo';


const MediaPost = ({ media }) => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const [post, setPost] = useState(null);

    useEffect(() => {
        dispatch(updateHeader('light'));
    }, [dispatch]);

    useEffect(() => {
        if (id) {
            const post = media.find(x => x.id === parseInt(id))
            setPost(post)
        }
    }, [id, media]);

    return (
        <div className="media-post-page">
            {post?.metaData?.seoData &&
                <SEO
                    title={post.metaData.seoData.title ? post.metaData.seoData.title  : "Paramount Wood Co. Media"}
                    description={post.metaData.seoData.description ? post.metaData.seoData.description  : "A collection of historic news and insights from Paramount Woof Co. descending in chronological order.If you wish to receive these articles in real time, please subscribe to our newsletter for regular updates and happenings from the studio."}
                    name="Paramount Wood Co."
                    type="website"
                    url={window.location.href}
                />
            }

            {post &&
                <>
                    <div className="media-post-intro">
                        <p>{formatDate(post.date)}</p>
                        <h1>{post.title}</h1>
                    </div>
                    <div className='content'>
                        <div className='html-text' dangerouslySetInnerHTML={{ __html: post?.content }} />

                        {post.images && post.images.length > 0 &&
                            <>
                                <h2>Image Gallery</h2>

                                <div className='gallery'>

                                    {post.images.map((item, i) => (
                                        <div key={i} className='gallery-item'>
                                            <img alt='' src={`${process.env.REACT_APP_WEBSITE_API}/api/images/optimize?filePath=images/media/${item?.fileName}&width=350&height=230&quality=99&format=webp`} />
                                        </div>
                                    ))}
                                </div>
                            </>
                        }

                        {post.videos && post.videos.length > 0 &&
                            <>
                                <h2>Video Gallery</h2>

                                <div className='gallery'>
                                    {post.videos.map((item, i) => (
                                        <div key={i} className='gallery-item'>
                                            <iframe src={item.url} width="100%" height="100%" title="Paramount Wood Co. Video" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                        </div>
                                    ))}
                                </div>
                            </>
                        }

                        <Link to="/media" className="cta gold-fill">Back to Media</Link>
                    </div>
                </>

            }

        </div>
    );
};


export default MediaPost;
