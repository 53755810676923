import React, { useState, useEffect } from 'react';
import { ArrowDown, ArrowUp } from 'react-feather';
import './styles.scss';

const Arrow = () => {
  const [atBottom, setAtBottom] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
        setAtBottom(true);
      } else {
        setAtBottom(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    atBottom ? (
      <div className="arrow back-to-top" onClick={scrollToTop}>
        <ArrowUp />
        <p>Back to top</p>
      </div>
    ) : (
      <div className="arrow bounce">
        <ArrowDown />
      </div>
    )
  );
};

export default Arrow;
