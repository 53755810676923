import { useCallback, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './styles.scss';
import { Minus, Plus, Info, Heart } from 'react-feather';
import { Swiper, SwiperSlide } from 'swiper/react';
import { setQuickAddItem, showPreview, updateCart, updateWishList } from '../../slices/app-slice';
import { updateHeader } from '../../slices/header-slice';

import 'swiper/css';
import 'swiper/css/navigation';

import Animate from '../../components/animate';
import { Navigation } from 'swiper/modules';
import { useDispatch, useSelector } from 'react-redux';
import Item from '../../components/item';
import { formatPrice } from '../../utility/utils';

import instagram from '../../assets/images/footer/instagram.svg';
import facebook from '../../assets/images/footer/facebook.svg';
import youtube from '../../assets/images/footer/youtube.svg';
import pinterest from '../../assets/images/footer/pinterest.svg';
import tiktok from '../../assets/images/footer/tiktok.svg';
import FeedbackModal from '../../components/feedback-modal';
import ReactImageMagnify from 'react-image-magnify';
import SEO from '../../components/seo';

const finishOptions = [
    {
        finish: "Antique Brass",
        image: "/images/base-finishes/antique_brass.png"
    },
    {
        finish: "Brass",
        image: "/images/base-finishes/brass.png"
    },
    {
        finish: "Burnished Black",
        image: "/images/base-finishes/burnished_black.png"
    },
    {
        finish: "Chrome",
        image: "/images/base-finishes/chrome.png"
    },
    {
        finish: "Copper",
        image: "/images/base-finishes/copper.png"
    },
    {
        finish: "Dark Oiled Bronze",
        image: "/images/base-finishes/dark_oiled_bronze.png"
    },
    {
        finish: "Gun Metal",
        image: "/images/base-finishes/gun_metal.png"
    },
    {
        finish: "Nickel",
        image: "/images/base-finishes/nickel.png"
    },
    {
        finish: "Silver",
        image: "/images/base-finishes/silver.png"
    }
]

const Product = ({ type }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id, variantId } = useParams();

    const [quantity, setQuantity] = useState(1);
    const [showReadMore, setShowReadMore] = useState(false);
    const [finishOption, setFinishOption] = useState(null);

    const [sizeOptions, setSizeOptions] = useState([]);
    const [sizeRequired, setSizeRequired] = useState(true);
    const [showMagnify, setShowMagnify] = useState(false);
    const [recommendedProducts, setRecommendedProducts] = useState([])
    const [product, setProduct] = useState({})

    const [preloadedImages, setPreloadedImages] = useState(null);
    const [isPreloading, setIsPreloading] = useState(true);

    const allItems = useSelector((state) => state.app);

    const preloadImage = (src) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = src;
            img.onload = () => resolve({ src, success: true });
            img.onerror = () => resolve({ src, success: false });
        });
    };

    useEffect(() => {
        if (product?.images) {
            setIsPreloading(true);
            setPreloadedImages(null)

            const preloadPromises = product.images.map((img) => {
                const imageSrc = `${process.env.REACT_APP_WEBSITE_API}/api/images/optimize?filePath=images/${type}/${img?.fileName}&width=1500&height=1500&quality=99&format=webp`;

                return Promise.all([
                    preloadImage(imageSrc),
                ]).then(() => ({
                    imageSrc
                }));
            });

            Promise.all(preloadPromises)
                .then((results) => {
                    const images = results.reduce((acc, result, i) => {
                        acc[i] = result;
                        return acc;
                    }, {});
                    setPreloadedImages(images);
                    setIsPreloading(false);
                })
                .catch((error) => {
                    console.error('Error preloading images:', error);
                });
        }
    }, [product, type]);

    const handleQuantityClick = useCallback((val) => {
        if (!sizeRequired) {
            if (product.stock) {
                if (val <= product.stock) {
                    setQuantity(val < 0 ? 0 : val)
                } else {
                    setQuantity(product.stock)
                }
            } else {
                setQuantity(val < 0 ? 0 : val)
            }
        }
    }, [product, sizeRequired])

    const addToWishList = (event) => {
        event.stopPropagation();

        dispatch(updateWishList({
            item: product,
            type: type
        }))

        dispatch(setQuickAddItem({
            item: product
        }))

        dispatch(showPreview(true))
    }

    // useEffect(() => {
    //     let array = [];
    //     let parentProduct;

    //     if (!isStructure) {
    //         if (product?.productId) {
    //             parentProduct = products.filter(p => p.id === product.productId)[0]
    //             array.push(parentProduct)
    //         }

    //         if (product.variants) {
    //             if (product.variants.length <= 4) {
    //                 product.variants.slice(0, product.variants.length).map((v) => array.push(v))
    //                 products.filter(x => (x.id !== product.id && x.title !== product.title)).slice(0, (4 - product.variants.length)).map((p) => array.push(p))
    //             } else {
    //                 if (product.variants.length > 4) {
    //                     product.variants.slice(0, 4).map((v) => array.push(v))
    //                 }
    //             }
    //         } else {
    //             if (parentProduct) {
    //                 products.filter(x => (x.id !== parentProduct.id && x.title !== parentProduct.title)).slice(0, 4).map((p) => array.push(p))
    //             } else {
    //                 products.filter(x => (x.id !== product.id && x.title !== product.title)).slice(0, 4).map((p) => array.push(p))
    //             }
    //         }
    //     } else {
    //         structures.filter(x => (x.id !== product.id && x.title !== product.title)).slice(0, 4).map((p) => array.push(p))
    //     }

    //     setRecommendedProducts(array)
    // }, [products, product, structures, isStructure]);

    useEffect(() => {
        let array = [];
        const items = allItems[type];

        if (items) {
            const item = items.find(x => x.id === parseInt(id))

            if (item?.variants) {
                if (item.variants.length <= 4) {
                    item.variants.slice(0, item.variants.length).map((v) => array.push(v))
                    items.filter(x => (x.id !== item.id && x.title !== item.title)).slice(0, (4 - item.variants.length)).map((p) => array.push(p))
                }
            } else {
                items.filter(x => (x.id !== item.id && x.title !== item.title)).slice(0, 4).map((p) => array.push(p))
            }
        }


        setRecommendedProducts(array)
    }, [allItems, id, type]);

    useEffect(() => {
        dispatch(updateHeader('light'))
    }, [dispatch]);

    // useEffect(() => {
    //     if (location.state.item) {
    //         setProduct(location.state.item)
    //     }
    // }, [location.state.item]);

    //IF base product changes
    useEffect(() => {
        if (type && id) {
            const items = allItems[type];

            if (items) {
                let p = items.find(x => x.id === parseInt(id))

                if (type === "products") {
                    setProduct(p)
                    setShowMagnify(true)
                    setSizeRequired(false)

                    if (p.variants) {
                        const options = p?.variants?.filter(x => x.type === "Size")

                        if (options?.length > 0) {
                            setSizeRequired(true)
                        } else {
                            setSizeRequired(false)
                        }

                        setSizeOptions(options)
                    }
                } else {
                    setProduct(p)
                }
            }
        }
    }, [type, id, allItems]);

    //IF the variant changes
    useEffect(() => {
        if (variantId) {
            const items = allItems[type];

            if (items) {

                if (type === "products") {
                    let p = items.find(x => x.id === parseInt(id))

                    const options = p?.variants?.filter(x => x.type === "Size")
                    setSizeOptions(options)

                    const variant = p.variants.find(x => x.id === parseInt(variantId))

                    if (variant?.type === "Size") {
                        setSizeRequired(false)
                    } else {
                        setSizeRequired(true)
                    }

                    setProduct(variant)
                    setShowMagnify(true)
                }
            }
        }
    }, [variantId, type, id, allItems]);

    // useEffect(() => {
    //     if (product) {
    //         const options = isVariant ? parentProduct?.variants?.filter(x => x.type === "Size") : product?.variants?.filter(x => x.type === "Size")
    //         setSizeOptions(options)

    //         if (!isVariant && options?.length > 0) {
    //             setSizeRequired(true)
    //         } else {
    //             setSizeRequired(false)
    //         }
    //     }
    // }, [product, isVariant, products, parentProduct?.variants]);

    const addToCart = useCallback(() => {
        dispatch(updateCart({
            product: product,
            quantity: quantity,
            total: product.price * quantity
        }))

        dispatch(setQuickAddItem({
            product: product,
            quantity: 1,
            total: product.price
        }))

        dispatch(showPreview(true))
    }, [dispatch, product, quantity])

    const inquire = useCallback(() => {
        navigate('/contact', { state: { product: product, finish: finishOption } });
    }, [navigate, product, finishOption])

    const onProductClick = (item) => {
        if (item.productId) {
            navigate({
                pathname: `/products/${item.id}/${parseInt(item.productId)}`,
                search: `?title=${item.title}`,
            })
        } else {
            if (type === "bases") {
                navigate({
                    pathname: `/bases/${item.id}`,
                    search: `?title=${item.title}`,
                })
            } else if (type === "slabs") {
                navigate({
                    pathname: `/slabs/${item.id}`,
                    search: `?title=${item.title}`,
                })
            } else {
                navigate({
                    pathname: `/products/${item.id}`,
                    search: `?title=${item.title}`,
                })
            }
        }
        window.scrollTo(0, 0);
    }

    const onFeedbackClose = () => {
        setShowReadMore(false)
    }

    const setSizeOption = (e) => {
        const { value } = e.target;
        const item = allItems[type].find(x => x.id === parseInt(id))

        if (value) {
            navigate(`/products/${item.id}/${parseInt(value)}`);
        } else {
            navigate(`/products/${item.id}`);
            setSizeRequired(true)
        }
    }

    console.log(window.location.href)

    return (
        <>
            {product &&
                <>
                    {product?.metaData?.seoData &&
                        <SEO
                            title={product.metaData.seoData.title ? product.metaData.seoData.title : "Paramount Wood Co."}
                            description={product.metaData.seoData.description ? product.metaData.seoData.description : ""}
                            name="Paramount Wood Co."
                            type="website"
                            url={window.location.href}
                        />
                    }
                    <div className="product-page">
                        <Animate>
                            <div className='product'>
                                <div className="product-gallery">
                                    {product?.images &&
                                        <Swiper
                                            slidesPerView={1}
                                            modules={[Navigation]}
                                            navigation={true}
                                        >
                                            {product?.images.map((img, i) => (
                                                <SwiperSlide key={i}>
                                                    {isPreloading ? (
                                                        <div className='image-loader'>
                                                            <div className='loader'></div>
                                                        </div>

                                                    ) : (
                                                        <>
                                                            {/* {showMagnify && preloadedImages
                                                                ? <ReactImageMagnify
                                                                    {...{
                                                                        className: "image-magnify-container",
                                                                        enlargedImageContainerClassName: "image-zoomed-container",
                                                                        smallImage: {
                                                                            isFluidWidth: true,
                                                                            src: preloadedImages[i]?.imageSrc,
                                                                            sizes: '(max-width: 480px) 100vw, (max-width: 1200px) 30vw, 360px'
                                                                        },
                                                                        largeImage: {
                                                                            src: preloadedImages[i]?.imageSrc,
                                                                            width: 2000,
                                                                            height: 2000
                                                                        },
                                                                        enlargedImageContainerDimensions: {
                                                                            width: '200%',
                                                                            height: '100%'
                                                                        },
                                                                        isHintEnabled: true,
                                                                        shouldHideHintAfterFirstActivation: false,
                                                                        hoverDelayInMs: 1500,
                                                                        imageClassName: "image-magnify",
                                                                        enlargedImagePosition: "over",
                                                                        lensStyle: { backgroundColor: 'rgba(0,0,0,.6)' }
                                                                    }}
                                                                />
                                                                : <img alt='' src={`${process.env.REACT_APP_WEBSITE_API}/api/images/optimize?filePath=images/${type}/${img.fileName}&width=1000&height=1000&quality=99&format=webp`} />
                                                            } */}
                                                            <img alt='' src={`${process.env.REACT_APP_WEBSITE_API}/api/images/optimize?filePath=images/${type}/${img.fileName}&width=1000&height=1000&quality=99&format=webp`} />
                                                        </>
                                                    )}
                                                    {/* <img alt='' src={`${process.env.REACT_APP_WEBSITE_API}/api/images/optimize?filePath=images/${isStructure ? product?.category : "products"}/${img.fileName}&width=1000&height=1000&quality=100&format=webp`} /> */}
                                                </SwiperSlide>
                                            ))}
                                        </Swiper>
                                    }
                                </div>

                                <div className="product-info">
                                    {(type === "products") ?
                                        <>
                                            <p className='product-category'>{product?.category}</p>
                                            <h1>{product?.title}</h1>

                                            <div className='price-wrapper'>
                                                {product.salePrice
                                                    ? <> <p className='strike-through'>{formatPrice(product?.price)}</p> <p className='sale-price'>{formatPrice(product?.salePrice)}</p><div className="pill coral">SALE</div></>
                                                    : <p className='product-price'>{formatPrice(product?.price)}</p>
                                                }
                                            </div>


                                            {product.stock > 0 &&
                                                <div className='product-quantity-wrapper'>
                                                    <div className='product-quantity'>
                                                        <div className='circle-btn' onClick={() => handleQuantityClick(quantity - 1)}><Minus /></div>
                                                        <div>{quantity}</div>
                                                        <div className='circle-btn' onClick={() => handleQuantityClick(quantity + 1)}><Plus /></div>
                                                    </div>

                                                    <div className='stock-indicator'><p>({product.stock} in stock)</p></div>
                                                </div>
                                            }

                                            <div className='product-actions'>
                                                {product.stock > 0 && sizeOptions?.length > 0 &&
                                                    <select onChange={(e) => setSizeOption(e)}>
                                                        <option value="">Select size</option>
                                                        {sizeOptions?.map((s) => (
                                                            <option value={s.id}>{s.title}</option>
                                                        ))}
                                                    </select>
                                                }

                                                {product.stock > 0
                                                    ? <button className='cta gold-fill' disabled={sizeRequired || !quantity} onClick={() => addToCart()}>Add to cart</button>
                                                    : <div className="pill bark">Sold out</div>
                                                }

                                                <Heart onClick={(e) => addToWishList(e)} className="wishlist-icon" />

                                                {/* <button className='cta wishlist' onClick={(e) => addToWishList(e)}><Heart className="wishlist-icon" />Add to wish list</button> */}

                                                {/* <button className='cta dark-fill'>Afterpay</button> */}
                                            </div>
                                        </>
                                        :
                                        <>
                                            <h1>{finishOption ? finishOption : ""} {product?.title}</h1>
                                            <button className='cta gold-fill mt-4' onClick={() => inquire()}>Inquire about availability</button>
                                        </>
                                    }

                                    <div className='socials'>
                                        <a href='https://www.instagram.com/paramountwoodco/' rel="noreferrer" target='_blank'><img src={instagram} alt='Paramount Wood Co. Instagram' /></a>
                                        <a href='https://www.facebook.com/ParamountWoodCo/' rel="noreferrer" target='_blank'><img src={facebook} alt='Paramount Wood Co. Facebook' /></a>
                                        <a href='https://www.youtube.com/@paramountwoodco7355/featured' rel="noreferrer" target='_blank'><img src={youtube} alt='Paramount Wood Co. Youtube' /></a>
                                        <a href='https://pinterest.com/paramountwoodcoshop/' rel="noreferrer" target='_blank'><img src={pinterest} alt='Paramount Wood Co. Pinterest' /></a>
                                        <a href='https://www.tiktok.com/@paramountwoodco' rel="noreferrer" target='_blank'><img src={tiktok} alt='Paramount Wood Co. TikTok' /></a>
                                    </div>

                                    <div className='product-description'>
                                        {type === "bases" && (product?.material !== "Wood" && product?.material !== "Other") &&
                                            <>
                                                <h3>Base finish options</h3>
                                                <div className='base-finish-options'>
                                                    {finishOptions.map((option, i) => (
                                                        <div key={i} className={`option ${option.finish === finishOption ? "active" : ""}`} title={option.finish} onClick={() => setFinishOption(option.finish)}>
                                                            <img src={option?.image} alt={option.finish} />
                                                        </div>
                                                    ))}
                                                </div>
                                            </>
                                        }

                                        {product.customShipping &&
                                            <div className='info'>
                                                <Info /><p>This item requires&nbsp;<span onClick={() => setShowReadMore(true)}>specialized shipping</span></p>
                                            </div>
                                        }

                                        {product?.specification &&
                                            <>
                                                <h3>Specification</h3>
                                                <div className='html-text' dangerouslySetInnerHTML={{ __html: product?.specification }} />
                                            </>
                                        }

                                        {(product?.description || product.startingPrice) &&
                                            <>
                                                <h3>Description</h3>
                                                {product.startingPrice && type !== "products" && <div className='starting-price'><p><span>Starting price:</span> {formatPrice(product.startingPrice)}</p></div>}
                                                {product.description && <div className='html-text' dangerouslySetInnerHTML={{ __html: product?.description }} />}
                                            </>
                                        }

                                    </div>
                                </div>
                            </div>
                        </Animate>

                        {recommendedProducts?.length > 0 &&
                            <div className='product-other'>
                                <h2>You may also like</h2>
                                <div className='items'>
                                    {recommendedProducts.map((item, i) => (
                                        <Item key={i} item={item} index={i} setItem={onProductClick} type={type} />
                                    ))}
                                </div>
                            </div>
                        }
                    </div>

                    {showReadMore &&
                        <FeedbackModal status={""} type={"info"} title={"Specialized Shipping"} message={"Some items are too large and heavy for conventional shipping methods. After your purchase, we will contact you to arrange an alternative at a competitive price."} onClose={onFeedbackClose} label={"Close"} />
                    }
                </>
            }
        </>
    )
}

export default Product;