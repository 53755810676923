import { useEffect, useRef } from "react"
import { useDispatch } from "react-redux"
import { updateHeader } from "../../slices/header-slice";
import { useInView } from "framer-motion";

import Animate from '../../components/animate';

import './styles.scss';
import { Link } from "react-router-dom";

const TableBuilder = () => {
    const dispatch = useDispatch();

    const ref = useRef(null);
    const isInView = useInView(ref, { once: false, amount: 0.1 });

    useEffect(() => {
        if (isInView) {
            dispatch(updateHeader('transparent'))

        } else {
            dispatch(updateHeader('dark'))
        }
    }, [isInView, dispatch]);

    useEffect(() => {
        dispatch(updateHeader('transparent'))
    }, [dispatch]);

    return (
        <div className="table-builder">
            <div ref={ref} className="page-hero">
                <div className="hero-content">
                    <h1>Table Builder</h1>
                    <p>Coming soon.</p>
                </div>
            </div>

            <Animate>
                <div className="builder-info">
                    <h2>The ultimate online customization experience.</h2>
                    <p>At Paramount Wood Co. we will soon be able to offer our customers the opportunity to build their own furniture online. This will be through a step-by-step configuration process, selecting specific design choices on the Size, Slab, Base and overall Finish of your furniture. With our powerful configurator tool, we put the power to design in your hands.</p>
                    <p>The Table Builder is under development, get in touch to learn more or wait for further announcements on our social channels.</p>
                    <Link to="/contact" className="cta gold-fill">get in touch</Link>
                </div>
            </Animate>

            

        </div>
    )
}

export default TableBuilder