import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Heart } from "react-feather";

import './styles.scss'
import { useNavigate } from "react-router-dom";

const WishList = () => {
  const wishlistItems = useSelector(state => state.app.wishList);
  const [total, setTotal] = useState(0);
  const navigate = useNavigate()

  useEffect(() => {
    if (wishlistItems && wishlistItems?.length) {
      setTotal(wishlistItems?.length)
    } else {
      setTotal(0)
    }

  }, [wishlistItems, wishlistItems?.length])


  return <div className="header-wishlist">
    <p onClick={() => navigate("/wishlist")}><Heart className="wishlist-icon" /> ({total})</p>
  </div>
}

export default WishList;