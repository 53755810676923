
import { useRef } from "react";
import { useInView } from "framer-motion";
import './styles.scss';

const Animate = ({ children }) => {
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true, amount: 0.05 });

    return (
        <div
            className="animate"
            ref={ref}
            style={{
                transform: isInView ? "none" : "translateY(100px)",
                opacity: isInView ? 1 : 0,
                transition: "all 0.5s cubic-bezier(0.17, 0.55, 0.55, 1) 0.2s"
            }} >
            {children}
        </div>
    )
}

export default Animate;