import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import ReactSlider from 'react-slider';
import './styles.scss'; // Create a CSS file for styling if needed

const PriceFilterSlider = forwardRef(({ minPrice, maxPrice, onFilterChange }, ref) => {
    const [value, setValue] = useState([minPrice, maxPrice]);

    useImperativeHandle(ref, () => ({
        resetSlider: () => setValue([minPrice, maxPrice])
    }));

    useEffect(() => {
        setValue([minPrice, maxPrice]);
    }, [minPrice, maxPrice]);

    const handleSliderChange = (newValue) => {
        setValue(newValue);
    };

    const handleSliderAfterChange = (newValue) => {
        // Ensure values are within min and max bounds
        const correctedValue = [
            newValue[0] < minPrice ? minPrice : newValue[0],
            newValue[1] > maxPrice ? maxPrice : newValue[1]
        ];
        setValue(correctedValue);
        onFilterChange(correctedValue);
    };

    return (
        <div className="price-filter-slider">
            <ReactSlider
                className="horizontal-slider"
                thumbClassName="price-thumb"
                trackClassName="price-track"
                min={minPrice}
                max={maxPrice}
                value={value}
                onChange={handleSliderChange}
                onAfterChange={handleSliderAfterChange}
                ariaLabel={['Lower thumb', 'Upper thumb']}
                ariaValuetext={(state) => `Thumb value ${state.valueNow}`}
                pearling
                minDistance={1}
            />
            <div className="price-labels">
                <p>${value[0]}</p>  <p>${value[1]}</p>
            </div>
        </div>
    );
});

export default PriceFilterSlider;
