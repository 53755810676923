import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import './styles.scss';
import { updateIsShowcase } from '../../slices/header-slice';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// import required modules
import Animate from '../../components/animate';
import Item from '../../components/item';
import { updateHeader } from '../../slices/header-slice';
import SEO from '../../components/seo';

const Portfolio = ({ setShowcaseItem }) => {
    const dispatch = useDispatch();

    const portfolio = useSelector((state) => state.app.portfolio);

    useEffect(() => {
        dispatch(updateHeader('light'))
    }, [dispatch]);

    const onShowCaseClick = (item) => {
        setShowcaseItem(item);
        dispatch(updateIsShowcase(true))
    }

    return (
        <>
            <SEO
                title="Projects — Paramount Wood Co."
                description="We make high end woodwork through dedicated craftsmanship, using only the rarest and finest materials in the world. We have a deep respect for the wood we use, and each piece is built on a unique story, but never one of deforestation. This is our promise."
                name="Paramount Wood Co."
                type="website"
                url="https://paramountwoodco.com/projects"
            />
            <div className="portfolio">

                <div className="portfolio-intro">

                    <h1>Our Portfolio</h1>
                    <p>Below we feature our hand-picked work across a range of wood species and projects designed to live in diverse environments and bring people together in meaningful ways. We hope you enjoy the work as much as we did making it.</p>
                </div>

                <Animate>
                    <div className="portfolio-content items">

                        {portfolio && portfolio?.map((item, i) => (
                            <Item key={i} item={item} index={i} setItem={onShowCaseClick} />
                        ))}
                    </div>
                </Animate>


            </div>
        </>
    )
}

export default Portfolio;