import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import './styles.scss';
import Animate from '../animate';
import Item from '../item';
import { updateIsShowcase } from '../../slices/header-slice';
import { useCallback } from 'react';
import Footer from '../footer';

const Showcase = ({ showCaseItem, setShowcaseItem }) => {
    const portfolio = useSelector((state) => state.app.portfolio);
    const products = useSelector((state) => state.app.products);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const onProductClick = (item) => {
        navigate(`/products/${item.id}`);
    }

    const closeShowcase = useCallback(() => {
        setShowcaseItem(null)
        dispatch(updateIsShowcase(false))
    }, [dispatch, setShowcaseItem])

    return <div className={`portfolio-showcase ${showCaseItem ? 'open' : ''}`}>
        <div className='showcase-wrapper'>
            <div className='showcase-info'>
                <div className='showcase-info-header'>
                    <p className='showcase-type'>{showCaseItem?.type}</p>
                    <h1><div className='back' onClick={() => closeShowcase()}>&#8592;</div>{showCaseItem?.title}</h1>

                    {showCaseItem?.species &&
                        <p>Species: {showCaseItem?.species}</p>
                    }

                    {showCaseItem?.year &&
                        <p>Year: {showCaseItem?.year}</p>
                    }
                </div>
                <div className='showcase-info-description'>
                    <p>{showCaseItem?.description}</p>
                </div>

                <div className='showcase-info-quote'>
                    <p>"{showCaseItem?.quote}"</p>
                </div>
            </div>
            <div className='showcase-images'>

                {showCaseItem?.images?.map((img, i) => (
                    <Animate key={i}>
                        <img alt='' src={`${process.env.REACT_APP_WEBSITE_API}/api/images/optimize?filePath=images/projects/${img.fileName}&width=1000&height=1000&quality=90&format=webp`} />
                    </Animate>
                ))}
            </div>
        </div>

        <div className='showcase-other'>
            <h2>Other Projects</h2>
            <div className='items'>
                {portfolio?.length && portfolio?.filter(x => x.id !== showCaseItem?.id).slice(0, 4).map((item, i) => (
                    <Item key={i} item={item} index={i} setItem={setShowcaseItem} />
                ))}
            </div>
        </div>

        <div className='showcase-other'>
            <h2>Check out our shop</h2>
            <div className='items'>
                {products?.length && products?.slice(0, 4).map((item, i) => (
                    <Item key={i} item={item} index={i} setItem={onProductClick} type='products' />
                ))}
            </div>
        </div>

        <Footer />
    </div>
}

export default Showcase;