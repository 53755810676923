import { useInView } from 'react-intersection-observer';
import Animate from '../../components/animate';
import Item from '../../components/item';
import DivergenceItem from '../divergence-item';

export const LazyLoadedGroup = ({ group, index, type, onProductClick }) => {
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    return (
        <div ref={ref} style={{ minHeight: '50vh'}}>
            {inView && (
                <Animate>
                    <div className="store-content items">
                        {group?.map((item, i) => (
                            <>
                                {item.type !== "DiningTable"
                                    ? <Item key={i} item={item} index={i} type={type} setItem={onProductClick} />
                                    :  <DivergenceItem key={i} item={item} index={i} type={item.type} setItem={onProductClick} />
                                }
                            </>
                           
                        ))}
                    </div>
                </Animate>
            )}
        </div>
    );
};