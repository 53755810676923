import axios from 'axios'

const authHeader = "cGFyYW1vdW50Om45IXU3QGpoI3lwRjZnY3F3enhi";

export const getApi = async () => {
    return await axios.get(`${process.env.REACT_APP_API}/api`)
}

export const register = async (email, password) => {
    return await axios({
        method: 'post',
        url: `${process.env.REACT_APP_API}/users/register`,
        data: {
            email: email,
            password: password
        }
    })
}

export const login = async (email, password) => {
    return await axios({
        method: 'post',
        url: `${process.env.REACT_APP_API}/users/login`,
        data: {
            email: "peter@test.com",
            password: "1234"
        }
    })
}

export const tokenizeCard = async (cardNumber, expirationDate, cvv) => {
    return await axios.post(
        `https://fts.cardconnect.com/cardconnect/rest/tokenize`,
        {
            account: "4444333322221111",
            expiry: "1225",
            cvv2: "123",
        },
        {
            headers: {
                Authorization: `Basic ${authHeader}`,
            },
        })
}


export const authorizePayment = async (data) => {
    return await axios.post(
        `${process.env.REACT_APP_WEBSITE_API}/api/payment/process-payment`,
        data
    )
}

export const contact = async (data) => {
    return await axios.post(
        `${process.env.REACT_APP_WEBSITE_API}/api/website/contact`,
        data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }
    )
}

export const customSubmission = async (data) => {
    return await axios.post(
        `${process.env.REACT_APP_WEBSITE_API}/api/website/custom`,
        data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }
    )
}

export const subscribeToNewsletter = async (data) => {
    return await axios.post(
        `${process.env.REACT_APP_WEBSITE_API}/api/website/subscribe`,
        data, {
            headers: {
                'Content-Type': 'application/json',
            },
        }
    )
}

export async function createShippingLabel(rate) {

    try {
        // Replace 'YOUR_API_KEY' with your actual Shippo API key
        const apiKey = process.env.REACT_APP_SHIPPO_TOKEN;

        const payload = {
            "rate": rate.object_id,
            "label_file_type": "PDF",
            "async": false,
            "carrier_account": rate.carrier_account,
            "servicelevel_token": rate.servicelevel.token,
            "label_subtype": "label"
          }

        // Make POST request to Shippo API
        const response = await axios.post('https://api.goshippo.com/transactions/', payload, {
            headers: {
                Authorization: `ShippoToken ${apiKey}`,
                'Content-Type': 'application/json',
            },
        });

        // Extract and return shipping rates from the response
        return response.data;
    } catch (error) {
        console.error('Error fetching shipping rates:', error.response.data);
        return null;
    }
}

export async function getShippingRates(payload) {
    try {
        const apiKey = process.env.REACT_APP_SHIPPO_TOKEN;
      

        // Make POST request to Shippo API
        const response = await axios.post('https://api.goshippo.com/shipments/', payload, {
            headers: {
                Authorization: `ShippoToken ${apiKey}`,
                'Content-Type': 'application/json',
            },
        });

        // Extract and return shipping rates from the response
        return response.data.rates;
    } catch (error) {
        console.error('Error fetching shipping rates:', error.response.data);
        return null;
    }
}