import { createSlice } from '@reduxjs/toolkit'

const getCartItems = () => {
    const items = localStorage.getItem('paramount_wood_cart');
    return items ? JSON.parse(items) : [];
}

const getWishListItems = () => {
    const items = localStorage.getItem('paramount_wood_wishlist');
    return items ? JSON.parse(items) : [];
}

export const appSlice = createSlice({
    name: 'app',
    initialState: {
        products: [],
        slabs: [],
        bases: [],
        portfolio: [],
        testimonials: [],
        employees: [],
        faqs: [],
        cart: getCartItems(),
        wishList: getWishListItems(),
        showPreview: false,
        quickAddItem: {}
    },
    reducers: {
        updateData: (state, action) => {
            state.products = action.payload.products;
            state.portfolio = action.payload.portfolio;
            state.testimonials = action.payload.testimonials;
            state.employees = action.payload.employees;
            state.slabs = action.payload.slabs;
            state.bases = action.payload.bases;
            state.faqs = action.payload.faqs;
            state.divergenceDiningTables = action.payload.divergenceDiningTables;

            const items = localStorage.getItem('paramount_wood_cart');
            const cartItems = items ? JSON.parse(items) : [];
            
            if (cartItems.length > 0) {
                state.cart = cartItems.map(cartItem => {
                    let product;
                    //variants
                    if (cartItem.product.productId) {
                        let parentProduct = state.products.find(
                            product => product.id === cartItem.product.productId
                        );

                        product = parentProduct?.variants.find(
                            product => product.id === cartItem.product.id
                        )
                    } else {
                        product = state.products.find(
                            product => product.id === cartItem.product.id
                        );
                    }

                    if (product) {
                        return {
                            ...cartItem,
                            product: product,
                            total: cartItem.quantity * product.price
                        };
                    }
                    return null;
                }).filter(Boolean); 

                localStorage.setItem('paramount_wood_cart', JSON.stringify(state.cart))
            }
        },
        updateCart: (state, action) => {
            const product = state.cart.find((cartItem) => cartItem.product.id === action.payload.product.id && cartItem.product.title === action.payload.product.title);
            console.log(action.payload.quantity)
            if (product) {
                if (product.product.stock > (action.payload.quantity + product.quantity)) {            
                    product.quantity += action.payload.quantity;
                } else {
                    product.quantity = product.product.stock
                }
            } else {
                state.cart = [...state.cart, action.payload]
            }

            localStorage.setItem('paramount_wood_cart', JSON.stringify(state.cart))
        },
        updateProductQuantity: (state, action) => {
            const product = state.cart.find((cartItem) => cartItem.product.id === action.payload.id && cartItem.product.title === action.payload.title);
            product.quantity = action.payload.quantity;
            product.total = parseFloat(((product.product.salePrice ? product.product.salePrice : product.product.price) * product.quantity).toFixed(2));

            localStorage.setItem('paramount_wood_cart', JSON.stringify(state.cart))
        },
        removeCartItem: (state, action) => {
            const products = state.cart.filter((cartItem) => cartItem.product.id !== action.payload);
            if (products) {
                state.cart = products;
                localStorage.setItem('paramount_wood_cart', JSON.stringify(state.cart))
            } else {
                localStorage.removeItem('paramount_wood_cart')
            }
        },
        showPreview: (state, action) => {
            state.showPreview = action.payload
        },
        setQuickAddItem: (state, action) => {
            state.quickAddItem = action.payload
        },
        updateWishList: (state, action) => {
            const item = state.wishList.find((listItem) => listItem.item.id === action.payload.item.id && listItem.item.title === action.payload.item.title);
            if (!item) {
                state.wishList = [...state.wishList, action.payload]
            } 

            localStorage.setItem('paramount_wood_wishlist', JSON.stringify(state.wishList))
        },
        removeWishListItem: (state, action) => {
            const items = state.wishList.filter((item) => item.item.id !== action.payload);
            if (items) {
                state.wishList = items;
                localStorage.setItem('paramount_wood_wishlist', JSON.stringify(state.wishList))
            } else {
                localStorage.removeItem('paramount_wood_wishlist')
            }
        }
    },
})

export const { updateData, updateCart, updateProductQuantity, removeCartItem, showPreview, setQuickAddItem, updateWishList, removeWishListItem } = appSlice.actions

export default appSlice.reducer